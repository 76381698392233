import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledTiles = styled.div`
	margin: 0;
	padding: 0 0 4rem;

	&.is-hidden-tablet {
		padding: 0;
		.pagination {
			display: none;
		}
		.columns.is-gapless:not(:last-child) {
			margin-bottom: 0;
		}
	}

	.pagination {
		padding-top: 2rem;
		margin: 0 auto;
		position: relative;

		.arrow-left {
			position: relative;
			cursor: pointer;
			border-right: 10px solid #6d6e71;
			border-bottom: 5px solid transparent;
			border-top: 5px solid transparent;
			right: 0.5rem;
		}
		.arrow-right {
			position: relative;
			cursor: pointer;
			border-left: 10px solid #6d6e71;
			border-bottom: 5px solid transparent;
			border-top: 5px solid transparent;
			left: 0.5rem;
		}
		button {
			width: 35px;
			font-weight: 700;
			font-size: 1.4rem;
			outline: none;
		}
		.underline {
			background-color: ${(p) => p.theme.color.grey};
			height: 2px;
			width: 20px;
			position: absolute;
			bottom: -2px;
			transition: left 0.5s;
		}
	}

	.column {
		background-color: ${(p) => p.theme.color.dark};
		&:nth-child(4n-2),
		&:nth-child(4n-1) {
			background-color: #6b6d71;
		}
	}
	.tile-holder {
		padding: 3rem ${(p) => p.theme.gutter} 4rem;
		color: #fff;

		h2 {
			text-align: center;
			font-family: ${(p) => p.theme.fonts.title};
			font-weight: 700;
			margin-bottom: 1.5rem;
		}

		p {
			font-family: ${(p) => p.theme.fonts.body};
			text-align: center;
		}

		.box-button {
			margin-top: 2rem;
		}
	}
	.columns {
		display: flex;
	}

	@media all and (min-width: 1200px) {
		.tile-holder {
			padding: 3rem 5rem;
		}
	}
	@media all and (min-width: 1600px) {
		.tile-holder {
			padding: 5rem 4rem;
		}
		.pagination button {
			font-size: 1.6rem;
		}
	}
	@media all and (max-width: 950px) {
		.column {
			&:nth-child(4n-1) {
				background-color: ${(p) => p.theme.color.dark};
			}
			&:nth-child(4n) {
				background-color: #6b6d71;
			}
		}
	}
	@media all and (max-width: 850px) {
		.tile-holder {
			padding: 2rem 4rem;
		}
		.column {
			&:nth-child(4n-1) {
				background-color: ${(p) => p.theme.color.dark};
			}
			&:nth-child(4n) {
				background-color: #6b6d71;
			}
		}
	}
	@media all and (max-width: 500px) {
		.tile-holder {
			padding: 2rem ${(p) => p.theme.gutterMobile};
		}
	}
`;

export default ({ tiles, postsPerPage, className }) => {
	const [tileIndex, setTileIndex] = useState(0);
	let tilePages = [];
	let indicators = [];

	let i = 0;
	while (i < Math.ceil(tiles.length / postsPerPage)) {
		tilePages.push(tiles.slice(i * postsPerPage, postsPerPage * (1 + i)));
		indicators.push(i + 1);
		i++;
	}

	const handleArrow = (direction) => {
		if (direction === 'right') {
			if (tileIndex < tilePages.length - 1) setTileIndex(tileIndex + 1);
		} else {
			if (tileIndex > 0) setTileIndex(tileIndex - 1);
		}
	};

	return (
		<StyledTiles className={className}>
			<div className="container">
				<div className="columns is-multiline is-gapless">
					{tilePages[tileIndex].map((item, index) => (
						<div
							className="column is-half-tablet is-full-mobile dark-orange-tile"
							key={index}
						>
							<div className="tile-holder">
								<h2>{item.shortTitle}</h2>
								<p>{item.excerpt}</p>
								<div className="box-button has-text-centered">
									<Link to={item.fields.slug} className="ec-button">
										{item.gotoText ? item.gotoText : 'Learn More'}
									</Link>
								</div>
							</div>
						</div>
					))}
				</div>
				<div
					className="pagination"
					style={{ width: `calc(35px * ${tilePages.length} + 20px)` }}
				>
					<span className="arrow-left" onClick={() => handleArrow('left')} />
					{indicators.map((item, index) => (
						<button
							className={tileIndex === index ? 'active' : ''}
							onClick={() => setTileIndex(index)}
							key={index}
						>
							{item}
						</button>
					))}
					<span className="arrow-right" onClick={() => handleArrow('right')} />
					<span
						className={`underline index-${tileIndex}`}
						style={{ left: `calc(17px + ${tileIndex} * 35px)` }}
					/>
				</div>
			</div>
		</StyledTiles>
	);
};
