import React from 'react';

import Masthead from '../components/masthead';
import Links from '../components/links';
import SectionTestimony from '../components/testimonial';

export default ({ data }) => {
	const { mast, links, testimony } = data.content.nodes[0].frontmatter;

	// Pull featured posts from settings.json
	let featuredFromSettings = [
		data.settings.nodes[0].featuredPost1,
		data.settings.nodes[0].featuredPost2,
		data.settings.nodes[0].featuredPost3,
	];

	let featured = [];
	let i, j;

	for (i in featuredFromSettings) {
		// Check if featured posts from settings.json exist
		let exists = false;
		for (j in data.posts.nodes) {
			if (data.posts.nodes[j].shortTitle === featuredFromSettings[i])
				exists = true;
		}
		// If post exists, add to featured array
		if (exists) featured.push(featuredFromSettings[i]);
	}

	// Make copy of posts array
	let allPosts = data.posts.nodes;

	// While featured array has less than 3 post titles in it, add latest posts in order
	while (featured.length < 3) {
		featured.push(allPosts[0].shortTitle);
		allPosts = allPosts.slice(1);
	}

	return (
		<>
			<Masthead title={mast.title} description={mast.text} image={mast.img} />
			<Links
				titles={links.titles}
				bimg={links.bimg}
				posts={data.posts.nodes}
				featured={featured}
				postsPerPage={data.settings.nodes[0].postsPerPage}
			/>
			<SectionTestimony data={testimony} />
		</>
	);
};
