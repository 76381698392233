// -----CATEGORY FUNCTIONALITY-----
// import React, { useState, useEffect } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import slugTransform from 'slug';

import Tiles from '../components/resource-tiles';

const StyledLinks = styled.div`
	background-image: url(${(p) => p.bimg});
	background-position: center bottom;
	background-size: 100% auto;
	background-repeat: no-repeat;

	.is-hidden-tablet {
		display: none;
		@media all and (max-width: 950px) {
			display: block;
		}
	}

	.is-hidden-mobile {
		display: block;
		@media all and (max-width: 950px) {
			display: none;
		}
	}

	.links-container {
		padding: 4rem ${(p) => p.theme.gutter} 2rem;
		display: flex;
		justify-content: center;
		text-align: center;
		.col {
			width: 45%;
			margin: 0 2.5%;

			h2 {
				color: ${(p) => p.theme.color.dark};
				font-weight: 700;
				margin-bottom: 0.5rem;
			}
			.links {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-direction: column;
				a,
				button {
					display: inline-block;
					color: ${(p) => p.theme.color.dark};
					position: relative;
					margin: 0 auto 1rem;
					font-size: 1rem;
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		@media all and (min-width: 1200px) {
			padding: 4rem ${(p) => p.theme.gutterWide};
		}
		@media all and (min-width: 1600px) {
			padding: 6rem ${(p) => p.theme.gutterXWide};
			.col .links {
				a,
				button {
					font-size: 1.3rem;
					line-height: 1.7rem;
				}
			}
		}
		@media all and (max-width: 768px) {
			padding: 4rem ${(p) => p.theme.gutter} 2rem;
			flex-wrap: wrap;
			.col {
				width: 100%;
				margin-bottom: 1rem;
			}
		}
		@media all and (max-width: 500px) {
			padding: 3rem ${(p) => p.theme.gutterMobile} 1rem;
		}
	}
`;

export default ({ titles, bimg, posts, featured, postsPerPage }) => {
	return (
		<StyledLinks bimg={bimg}>
			<div className="links-container">
				{!!featured && !!featured.length ? (
					<>
						<div className="col">
							<h2>{titles[0]}</h2>
							<div className="links">
								<Link
									to={`/resources/${slugTransform(featured[0], {
										lower: true,
									})}/`}
								>
									{featured[0]}
								</Link>
								<Link
									to={`/resources/${slugTransform(featured[1], {
										lower: true,
									})}/`}
								>
									{featured[1]}
								</Link>
								<Link
									to={`/resources/${slugTransform(featured[2], {
										lower: true,
									})}/`}
								>
									{featured[2]}
								</Link>
							</div>
						</div>
						<div className="col">
							<h2>{titles[2]}</h2>
							<div className="links">
								{posts.slice(0, 3).map((x, i) => (
									<Link to={x.fields.slug} key={i}>
										{x.shortTitle}
									</Link>
								))}
							</div>
						</div>
					</>
				) : (
					undefined
				)}
			</div>
			<Tiles
				tiles={posts}
				postsPerPage={postsPerPage}
				className="is-hidden-mobile"
			/>
			<Tiles tiles={posts} postsPerPage={9999} className="is-hidden-tablet" />
		</StyledLinks>
	);
};
