import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ResourcesTemplate from '../templates/resources-template';
import SEO from '../components/seo';

export default ({ data }) => {
	return (
		<Layout page="Resources">
			<SEO
				title="Resources"
				description={data.content.nodes[0].frontmatter.description}
			/>
			<ResourcesTemplate data={data} />
		</Layout>
	);
};

export const query = graphql`
	{
		content: allMarkdownRemark(
			filter: { frontmatter: { slug: { eq: "resources" } } }
		) {
			nodes {
				frontmatter {
					description
					testimony {
						author
						location
						content
					}
					mast {
						title
						text
						img
					}
					links {
						titles
						bimg
					}
				}
			}
		}
		posts: allPostsJson(sort: { fields: date, order: DESC }) {
			nodes {
				shortTitle
				excerpt
				fields {
					slug
				}
			}
		}
		settings: allSettingsJson {
			nodes {
				featuredPost1
				featuredPost2
				featuredPost3
				postsPerPage
			}
		}
	}
`;
